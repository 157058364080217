/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint no-param-reassign:  [2, { "props": false }] */
import React from "react";
import styles from "./CustomProductOption.module.css";

const checkIcon = require("./imgs/check-icon.svg");
const checkLightIcon = require("./imgs/check-light-icon.svg");

function CustomSingleOption({
  image,
  name,
  onChange,
  isSelected,
  price,
  currency,
  renderPrice,
  oos,
}) {
  const removePaddingClass =
    name.length > 12
      ? [styles.topMargin0, styles.bottomMargin0].join(" ")
      : styles.topMargin0;
  return (
    <div
      className={`${styles.singleWrapper} ${oos === 1 ? styles.oos : ''} relative flex items-center justify-between`}
    >
      <div
        className={`${styles.noImagePlaceHolderSimple} md:absolute left-[-10%] top-[-20%]`}
      >
        <img
          alt={name}
          src={image}
          onError={i => {
            i.target.style.display = "none";
            return null;
          }}
          className="w-[85px] h-[85px] lg:w-[106px] lg:h-[106px] object-contain"
        />
        <p className="hidden md:block">{renderPrice(price, currency)}</p>
      </div>
      <h3
        className={`${removePaddingClass} w-[80%] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-dark dark:text-white`}
      >
        {name}
      </h3>

      <div className="flex flex-col items-end justify-end">
        { oos === 1 ? null : (
        <div
          onClick={onChange}
          className={`
                ${styles.singleContainer} 
                ${
                  isSelected
                    ? "bg-dark dark:bg-button border-none"
                    : "bg-white dark:bg-disabled dark:border-none border-2 border-borderLight"
                } 
                md:absolute right-[-10%] top-[80%]
                w-11 h-11 rounded-full 
                flex items-center text-center
              `}
        >
          <div className="w-11 h-11 flex items-center">
            <img
              alt="check"
              src={checkLightIcon}
              className={`${isSelected ? "hidden" : "block"} m-auto `}
            />
            <img
              alt="check"
              src={checkIcon}
              className={`${isSelected ? "block" : "hidden"} m-auto `}
            />
          </div>
        </div>
        ) }
        <p className="block md:hidden">{renderPrice(price, currency)}</p>
      </div>
    </div>
  );
}

export default CustomSingleOption;
